import React from "react"
import {TypeAnimation} from 'react-type-animation';

const Hero = () => {
  return (
    <div className="container relative">
      <div className="h-screen flex justify-center flex-col items-center md:items-start">
        <h1 className="text-3xl tracking-tight font-bold text-gray-900 md:text-4xl md:ml-20">
          <TypeAnimation 
          className="-mt-24"
          sequence={['猫と和解せよ。', 5000, '環境とコンテンツに恩返しを。'] }
          speed={10}
          deletionSpeed={8}
          cursor
          />
        </h1>
      </div>
    </div>
  )
}

export default Hero
